<template>
  <div id="import-booking-page">
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col cols="12" sm="6" lg="4" xl="3">
              <b-form-group label="Localitzador" label-for="bookingId">
                <b-form-input
                  id="bookingId"
                  v-model="bookingId"
                  placeholder="Nº reserva channel manager"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="mb-2 d-flex justify-content-center justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="onCancelButtonClicked">
          Cancel·la
        </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button block variant="primary" @click="onImportButtonClicked">
          Envia
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";
import { notifyError } from "@/utils/methods";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      bookingId: null,
    };
  },
  methods: {
    onCancelButtonClicked() {
      this.$router.push({ name: "foravila-bookings" });
    },
    onImportButtonClicked() {
      this.$store.dispatch("app/setLoading", true);

      // TODO: validate fields

      this.$store
        .dispatch("bookings/sendBookingToCloudbeds", this.bookingId)
        .then((data) => {
          this.bookingId = null;
          this.$swal({
            title: "Reserva enviada a CloudBeds",
            text:
              "La reserva ha estat importada correctament a CloudBeds amb l'ID de reserva " +
              data.reservationID,
            confirmButtonText: "D'acord",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        })
        .catch(() =>
          notifyError(
            "Reserva no importada",
            "La reserva no s'ha pogut importar correctament a CloudBeds"
          )
        )
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>
